/* eslint-disable global-require */
import { ethers } from 'ethers';
import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react'
// if (location.search && location.search.match(/enter-test-mode/)) localStorage.setItem('IsWolfTownTest', 'true');
// localStorage.setItem('IsWolfTownTest', 'true');
// const IsTest = localStorage.getItem('IsWolfTownTest') === 'true';
let DEBUG_ADDRESS = '';

export const WolfConfig = () => {
  useEffect(() => {
    console.log("document", document);
    global.document = window.document;



    // eslint-disable-next-line no-restricted-globals
    const url = new URLSearchParams(location.search || '');
    const observation = url.get('observation');
    if (observation) DEBUG_ADDRESS = observation;
  })
}





export const base36_to_num = (value) => {
  var str_to_num = {
    f: '0',
    m: '1',
    r: '2',
    c: '3',
    x: '4',
    e: '5',
    t: '6',
    j: '7',
    p: '8',
    a: '9',
    z: '10',
    h: '11',
    v: '12',
    l: '13',
    s: '14',
    d: '15',
    n: '16',
    w: '17',
    g: '18',
    y: '19',
    k: '20',
    b: '21',
    u: '22',
    o: '23',
    q: '0',
    i: '0',
  }

  var l = value.length

  var v = value.toString().toLowerCase()
  var ret = 0
  for (var i = l - 1; i >= 0; i--) {
    ret = Number(ret) * 24 + Number(str_to_num[v.slice(i, i + 1)])
  }
  return ret
}
export const num_to_base36 = (value) => {
  var num_to_str = {
    0: 'F',
    1: 'M',
    2: 'R',
    3: 'C',
    4: 'X',
    5: 'E',
    6: 'T',
    7: 'J',
    8: 'P',
    9: 'A',
    10: 'Z',
    11: 'H',
    12: 'V',
    13: 'L',
    14: 'S',
    15: 'D',
    16: 'N',
    17: 'W',
    18: 'G',
    19: 'Y',
    20: 'K',
    21: 'B',
    22: 'U',
    23: 'O',
    /*    0: 'Q',
       0: 'I', */
  }
  if (value == 0) return '$$'
  var ret = ''
  var v = value
  while (v > 0) {
    ret = ret + num_to_str[v % 24]
    v = Math.floor(v / 24)
  }
  v = 0
  while (ret.length < 4) {
    if (v === 0) ret = ret + 'F'
    else if (v === 1) ret = ret + 'Q'
    else if (v === 2) ret = ret + 'I'
    else if (v === 3) ret = ret + 'F'
    v++
  }

  return ret
}



const IsTest = false;
const IsDevelopment = process.env.NODE_ENV === 'development';
export const Constants = {
  ArenaHistoryBlock: (24 * 60 * 60 * 30) / 3,
  DEBUG_ADDRESS,
  IsDevelopment,
  DEFAULT_SEED: '0x0000000000000000000000000000000000000000000000000000000000000000',
  /**
   * 每个邀请最多可持续的时间 (hours)
   */
  INVITATION_DURATION: 72,

  IsTest,
  BASE_URL: 'https://app.wolftown.games/animals',
  BASE_IMAGE_URL: 'https://app.wolftown.games/images/animals',
  API_SERVE: 'https://app.wolftown.games',
  OPENSEA_URL: 'https://opensea.io',
  Chain: {
    ID: '56',
    PRC: 'https://bsc-dataseed.binance.org/',
    Name: 'Binance Smart Chain Mainnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    ScanView: 'https://bscscan.com',
    AddressView: 'https://bscscan.com/address',
    TxView: 'https://bscscan.com/tx',
  },

  emergencyWolfLeave: false,

  Building: {
    OwnershipTotal: 10000000,
  },
  Contract: {
    BankAddress: '0x1f0c693F5cD661126e43b25a96FA703a0C8F2543',
    WTWool: '0xAA15535fd352F60B937B4e59D8a2D52110A419dD',
    /* 0xEa38779a32C2f044719FfE5aF8f4D9DE9D6757f6 */
    WTMilk: '0x60Ca032Ba8057FedB98F6A5D9ba0242AD2182177',
    Random: '0x536D40ab94F323b3CBF50DFf24d6fa636Bc170c3',
    /* 0xc7ba9e206cBE7016F2771C9FFc95702DcbbB257b */
    WTAnimal: '0x14f112d437271e01664bb3680BcbAe2f6A3Fb5fB',
    OldWTAnimal: '0xE686133662190070c4A4Bea477fCF48dF35F5b2c',
    Barn: '0x143E742f319d1FE0d1a825d451fc937B5A706702',
    /* 0x10A6DC9fb8F8794d1Dc7D16B035c40923B148AA4 */
    OldBarn: '0x58eaBB38cc9D68bEA8E645B0f5Ec741C82f2871B',
    OldBarnBUG: '0x386500b851CA1aF027247fa8Ab3A9dDd40753813',
    V1AnimalTransfer: '0xCe487D0Ab195D28FE18D5279B042498f84eb051F',
    WoolfTownAPI: '0x301216c75E8af09a3785c9fE7077c543eBB77B6F',
    SkillManager: '0xAef63919ac27d048d9e0c31da474AD0FEedB141a',
    BuildingGameManager: '0xbA58c345cA328F8bfA6A5607a15C2128CC6fBE61',
    WTOwnershipDeed: '0xb61afda2288C4E8AC896B6d4E78BC0ca0C5D98DC',
    WTOwnershipDeedURI: '0x71C0d09D512Fd51BCC96d3ee380a51448635d7DA',
    ForestExploration: '0x6fbaAAF642D9d4A9fC0d2270035D91e100B4B3bC',
    Fight: '0x9851E7eFc67F48E52E98454149793B4eA219F1c5',
    Arena: '0x5f189c322d829aD8F3901A17c54DcC893345d8eB',
    BuildingStakeManager: '0x7c176E44B6e925E8b5F0Fafc7b2Ec2876A6fE8aD',
    WT_LP_USDT: '0xcAad85D48f31177040E25048FF4dd875eAE9Dea7',
    WT_LP_BNB: '0xe9C7bc98901d1B71d63902602Bff6E37dCdE79fC',
    USDT: '0x55d398326f99059fF775485246999027B3197955',
    DONATE: '0xfaCaC2d69E6dcF16bf9760651b7a71D5e7eFe4D5',
    BUSD: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    WTanimalPlus: '0x6EB7f2B5A027d28DA36914997251c08df00c3eB4',
    WTMilkPlus: '0xEc9a21a2Dc6FCc6997Ba77776899302c5dD26A4e',
    WTAnimalLevel: '0x1dea7fCe4572C87c13DA1e8E94571EDB350c401A',
    wtLandMinter: '0x036fcc48fE0094444D0A6d6519150fd8d04cE04A',
    wtLandMinterByBIBI: '0xD19f634D95ab6b8a634190BE0f141d894Efb62ef',//ADMIN 0x12422818480293cd09A16C4c35fDE508E60E2611
    BIBIToken: '0xfe8bf5b8f5e4eb5f9bc2be16303f7dab8cf56aa8'

  },
};
export const AbiConfig: Record<keyof typeof Constants.Contract, any> = {
  BankAddress: require('config/abi/wtWool'),
  WTWool: require('config/abi/wtWool'),
  WTMilk: require('config/abi/wtWool'),
  Random: require('config/abi/wtWool'),
  WTAnimal: require('config/abi/wtAnimal.json'),
  OldWTAnimal: require('config/abi/wtAnimal.json'),
  Barn: require('config/abi/wtBarn.json'),
  OldBarn: require('config/abi/wtBarn.json'),
  OldBarnBUG: require('config/abi/wtBarn.json'),
  V1AnimalTransfer: require('config/abi/V1AnimalTransfer.json'),
  WoolfTownAPI: require('config/abi/WoolfTownAPI.json'),
  SkillManager: require('config/abi/SkillManager.json'),
  BuildingGameManager: require('config/abi/BuildingGameManager.json'),
  WTOwnershipDeed: require('config/abi/WTOwnershipDeed.json'),
  WTOwnershipDeedURI: require('config/abi/WTOwnershipDeed.json'),
  ForestExploration: require('config/abi/ForestExploration.json'),
  Arena: require('config/abi/Arena.json'),
  Fight: require('config/abi/Fight.json'),
  BuildingStakeManager: require('config/abi/BuildingStakeManager.json'),
  WT_LP_USDT: require('config/abi/lp.json'),
  WT_LP_BNB: require('config/abi/lp.json'),
  USDT: require('config/abi/wtWool'),
  DONATE: require('config/abi/donate.json'),
  BUSD: require('config/abi/wtWool'),
  WTanimalPlus: require('config/abi/wtWool'),
  WTMilkPlus: require('config/abi/wtWool'),
  WTAnimalLevel: require('config/abi/WTAnimalLevel.json'),
  wtLandMinter: require('config/abi/wtLandMinter.json'),
  wtLandMinterByBIBI: require('config/abi/wtLandMinterBIBI.json'),


  BIBIToken: require('config/abi/wtWool.json'),
};
if (!IsDevelopment) {
  console.log = () => null;
  console.error = () => null;
  console.info = () => null;
}
if (IsTest) {
  Object.assign(Constants, {
    BASE_URL: 'https://app.test.wolftown.world/animals',
    BASE_IMAGE_URL: 'https://app.test.wolftown.world/images/animals',
    API_SERVE: 'https://app.test.wolftown.world',
    Chain: {
      ID: '97',
      PRC: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
      Name: 'Binance Smart Chain Testnet',
      nativeCurrency: {
        name: 'tBNB',
        symbol: 'tBNB',
        decimals: 18,
      },
      ScanView: 'https://testnet.bscscan.com',
      AddressView: 'https://testnet.bscscan.com/address',
      TxView: 'https://testnet.bscscan.com/tx',
    },
    Contract: {
      // api
      WoolfTownAPI: '0xbFE5a21f0c153144BE0c65BE961b01Bf52EE1B0f',

      // contract
      WTWool: '0x808156c8510f3b07209762d1357da452Bc740067',
      WTMilk: '0x98949d35a2cb9400643B8FB5393266eD9CDb0f18',
      Random: '0x5b52eD1Eed64A5b98b244D090682e011F6f502be',
      /*  WTAnimal: '0x0E606A0c4042C4183eD183f9245cEb616ec4D458', */
      WTAnimal: '0x51D4767a6177A0f20c1c04f5A14724E1CBb90603',
      OldWTAnimal: '0xBE66EA1D143700857A5686eCA9D91d5BC6A3BA36',
      Bannable: '0x901E981335272C3817663692AdE7A2b9f7Af1430',
      /* Barn: '0x3d227278b0c500918ad5D1B70a04fDb2cE63F645', */
      Barn: '0x0d9d038f279abe87051095BEEa88474ef084917d',
      OldBarn: '0xa87FA735B0e2ec0ECae06b7552D237BBe044ef7B',
      OldBarnBUG: '0xB2b4bc0ce285CFe10Bf93e09E0fDEB93f41d1fdB',
      V1AnimalTransfer: '0x74aBd8Ea56510D49c373CBc99ECB3d1e204B479F',
      WoolPool: '0x0e31631B9C0d59c946e6F08d1c9A8Bc4e567C413',
      SkillManager: '0x784Ffbb7E630F958Ca0586B0487Edb2cBfe249CD',
      BuildingGameManager: '0x5CA7800F3C8c3E98C769DC5d5b4d119aA2ac1728',
      BuildingStakeManager: '0x9B2F1d8Ddcf9d19505bAaF0a71E670b0eac7D424',
      WTOwnershipDeed: '0xbaE4e86071f5FDBac225546aaC76B9Ae587051Dc',
      WTOwnershipDeedURI: '',
      ForestExploration: '0x8844FE9f80c5C37E7C5BE678A97c36064986334e',
      Arena: '0x176f53ADc7d475356488BB0EA658Baa5507D1E1b',
      Fight: '0x6f4c48B1E47Db04564a067F2D0ac34Bc76Da7C2d',
      WT_LP_USDT: '0x03BAE7A09f02C0D63dfA1C7196E0A8D60aeBcf81',
      WT_LP_BNB: '0x7C7747716545F19cdF0257A23C5dE2fa184BDb1D',
      USDT: '0x7ef95a0FEE0Dd31b22626fA2e10Ee6A223F8a684',
      DONATE: '0xfaCaC2d69E6dcF16bf9760651b7a71D5e7eFe4D5',
      BUSD: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      WTanimalPlus: '0x85849483E38FB4EF8bb4400c868916c75071b6f4',
      WTAnimalLevel: '0x1dea7fCe4572C87c13DA1e8E94571EDB350c401A',
      wtLandMinter: '0x036fcc48fE0094444D0A6d6519150fd8d04cE04A',
      BIBIToken: '0xfe8bf5b8f5e4eb5f9bc2be16303f7dab8cf56aa8'
    },
  });
}
