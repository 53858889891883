import React from 'react'
import styled from 'styled-components'
import { CogIcon, IconButton, Text } from '@pancakeswap/uikit'
import { MENU_ENTRY_HEIGHT } from '../config/config'
import { PanelProps, PushedProps } from '../types'
// import CakePrice from "./CakePrice";
// import ThemeSwitcher from "./ThemeSwitcher";
import SocialLinks from './SocialLinks'
import LangSelector from './LangSelector'

interface Props extends PanelProps, PushedProps {}

const Container = styled.div`
  flex: none;
  // padding: 8px 4px;
  // background-color: ${({ theme }) => theme.nav.background};
  // border-top: solid 2px rgba(133, 133, 133, 0.1);
`

const SettingsEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${MENU_ENTRY_HEIGHT}px;
  padding: 0 0px;
`

const SocialEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${MENU_ENTRY_HEIGHT}px;
  padding: 0 16px;
  border-top: solid 1px #333;
`

const PanelFooter: React.FC<Props> = ({
  isPushed,
  pushNav,
  toggleTheme,
  isDark,
  cakePriceUsd,
  currentLang,
  langs,
  setLang,
}) => {
  if (!isPushed) {
    return (
      <Container>
        <IconButton variant="text" onClick={() => pushNav(true)}>
          <CogIcon />
        </IconButton>
      </Container>
    )
  }

  return (
    <Container>
      <SettingsEntry>
        {langs && langs.length > 0 && <LangSelector currentLang={currentLang} langs={langs} setLang={setLang} />}
        {
          cakePriceUsd && <Text color="textSubtle" bold mr="20px">
            ${cakePriceUsd}
          </Text>
        }
      </SettingsEntry>
      <SocialEntry>
        <SocialLinks />
      </SocialEntry>
    </Container>
  )
}

export default PanelFooter
