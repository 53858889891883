import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { perpLangMap } from 'utils/getPerpetualLanguageCode'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { MenuEntry } from '../types'
import {
  WolfIcon,
  BarnIcon,
  SheepIcon,
  GiftIcon,
  MarketplaceIcon,
  WolfWhiteIcon,
  BarnWhiteIcon,
  SheepWhiteIcon,
  MarketplaceWhiteIcon,
  GiftWhiteIcon,
  IdoIcon,
  LandIcon
} from '../icons'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

export const MENU_HEIGHT = 64
export const MENU_ENTRY_HEIGHT = 48
export const SIDEBAR_WIDTH_FULL = 240
export const SIDEBAR_WIDTH_REDUCED = 56

export const sideMenuLinks: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'Wolf',
    href: '/',
  },
  // {
  //   label: t('Barn V1'),
  //   icon: 'Barn',
  //   href: '/barn',
  // },
  {
    label: t('Barn V2'),
    icon: 'Barn',
    href: '/barnv2',
  },
  {
    label: t('Minting'),
    icon: 'Sheep',
    href: '/minting',
  },
  {
    label: t('Land'),
    icon: 'Land',

    items: [
      {
        label: t('BIBI'),
        icon: 'Sheep',
        href: '/bibi',
      },
      {
        label: t('Land'),
        icon: 'Land',
        href: '/land',
      }]

  },
  {
    label: t('My NFTs'),
    icon: 'Gift',
    href: '/my-animals',
  },
  {
    label: t('Marketplace'),
    icon: 'Marketplace',
    href: '/market',
  },

  {
    label: t('Ido'),
    icon: 'ido',
    href: '/ido',
  },






]

export const socials = [
  {
    label: 'Twitter',
    icon: 'Twitter',
    href: 'https://twitter.com/WolfTownWorld',
  },
  {
    label: 'Telegram',
    icon: 'Telegram',
    items: [
      {
        label: 'English',
        href: 'https://t.me/WolfGameDao_CN',
      },
      {
        label: '中文',
        href: 'https://t.me/WolfGameDao_CN',
      },
    ],
  },

  {
    label: 'Discord',
    icon: 'Discord',
    href: 'https://discord.com/invite/CTQyZyaB34',
  },

  {
    label: 'GameFi',
    icon: 'GameFi',
    href: 'https://t.me/MATEGAME',
  },
]

const config: (t: ContextApi['t'], languageCode?: string) => ConfigMenuItemsType[] = (t, languageCode) => [
  {
    label: t('Home'),
    href: '/',
    icon: WolfIcon,
    fillIcon: WolfIcon,
    showItemsOnMobile: false,
    height: "15px",
    width: "15px",
    items: [],
  },
  {
    label: t('Barn'),
    href: '/barnv2',
    icon: BarnIcon,
    fillIcon: BarnIcon,
    height: "15px",
    width: "15px",
    showItemsOnMobile: false,
    items: []
  },

  {
    label: t('Mint'),
    href: '#',
    icon: SheepIcon,
    fillIcon: SheepIcon,

    items: [
      {
        label: t('Animals'),
        icon: 'Sheep',
        href: '/minting',
      },
      {
        label: t('Land'),
        icon: 'Land',
        href: '/land',
      }, {
        label: t('BIBI'),
        icon: 'Sheep',
        href: '/bibi',
      },],
  },

  {
    label: t('My NFTs'),
    href: '/my-animals',
    icon: GiftIcon,
    fillIcon: GiftIcon,
    showItemsOnMobile: false,
    items: [],
  },

  {
    label: t('Marketplace'),
    href: '/market',
    icon: MarketplaceIcon,
    fillIcon: MarketplaceIcon,
    showItemsOnMobile: false,
    hideSubNav: true,
    items: [],
  },
]

export default config
