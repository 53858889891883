import { BigNumber } from '@ethersproject/bignumber'
import { API_SERVICE_URL } from 'config'
import wtAnimalABI from 'config/abi/wtAnimal.json'
import wtLandABI from 'config/abi/wtLand.json'
import wtBarnABI from 'config/abi/wtBarn.json'
import wtBarnStakeOfABI from 'config/abi/wtBarnStakeOf.json'
import multicall, { multicallv2 } from 'utils/multicall'
import { getWtAnimalAddress, getWtBarnAddress, getWtLandAddress } from 'utils/addressHelpers'
import { LAND_API_ENDPOINT } from 'config'

export const fetchAnimals = async (ids: number[]) => {
  const groupSize = ids.length / 100 + (ids.length % 100 === 0 ? 0 : 1)

  const groupedAnimals = await Promise.all(
    Array.from({ length: groupSize }, async (_, i) => {
      const groupIds = ids.slice(i * 100, i * 100 + 100)
        const url = `${API_SERVICE_URL}/animals?ids=${encodeURIComponent(JSON.stringify(groupIds))}`  
    
     const response = await fetch(url)
      const animals = await response.json()
      return animals
    }),
  )
  const traits = groupedAnimals.reduce((acc, group, i) => {
    Object.assign(acc, group)
    return acc
  }, {})
  return ids.map((id) => {
    return {
      id,
      name: traits[id].name,
      isSheep: traits[id].name.includes('Sheep'),
      image: traits[id].image,
      imageSmall: traits[id].imageSmall,
      attributes: traits[id].attributes,
    }
  })
}

const fetchUserAnimals = async (account) => {
  const call = [
    {
      address: getWtAnimalAddress(),
      name: 'balanceOf',
      params: [account],
    },
  ]
  const [[balanceOf]] = await multicall(wtAnimalABI, call)
  const count = balanceOf.toNumber()
  const idCalls = Array.from({ length: count }, (_, i) => {
    return {
      address: getWtAnimalAddress(),
      name: 'tokenOfOwnerByIndex',
      params: [account, i],
    }
  })
  const ids = await multicall(wtAnimalABI, idCalls)
  const animals = await fetchAnimals(ids.map((id) => id[0].toNumber()))
  return { balance: count, animals }
}

export const fetchStakedAnimals = async (account) => {

  const call = [
    {
      address: getWtBarnAddress(),
      name: 'totalStakesOf',
      params: [ account ],
    }
  ]
  const [[balanceOf]] = await multicall(wtBarnABI, call)
  const count = balanceOf.toNumber()

  const stakeCalls = Array.from({ length: count }, (_, i) => {
    return {
      address: getWtBarnAddress(),
      name: 'stakeOf',
      params: [account, i],
    }
  })

  const stakes = await multicall(wtBarnStakeOfABI, stakeCalls)
  const ids = stakes.map((s) => s[0].tokenId.toNumber())

  const gatheredAndEarningCalls = ids.map(id => {
    return {
      address: getWtBarnAddress(),
      name: 'gatheredTokens',
      params: [id],
    }
  }).concat(ids.map(id => {
    return {
      address: getWtBarnAddress(),
      name: 'estimateEarning',
      params: [id],
    }
  }))

  const results = await multicall(wtBarnABI, gatheredAndEarningCalls)
  const gatheringTypes = results.slice(0, count).map(([t]) => t)
  const earnings = results.slice(count)
  const rawAnimals = await fetchAnimals(ids)
  const animals = rawAnimals.map((a, i) => {
    a['gatheredTokens'] = gatheringTypes[i]
    a['earnings'] = { wool: earnings[i][0].toString(), milk: earnings[i][1].toString()  }
    return a
  })
  return { balance: count, animals }
}

export const fetchTotalEarnings = async (account: string) => {
  const call = [
    {
      address: getWtBarnAddress(),
      name: 'totalStakesOf',
      params: [account],
    },
  ]
  const [[balanceOf]] = await multicall(wtBarnABI, call)
  const count = balanceOf.toNumber()

  const stakeCalls = Array.from({ length: count }, (_, i) => {
    return {
      address: getWtBarnAddress(),
      name: 'stakeOf',
      params: [account, i],
    }
  })
  const stakes = await multicall(wtBarnStakeOfABI, stakeCalls)
  const ids = stakes.map((s) => s[0].tokenId.toNumber())

  const earningsCall = [
    {
      address: getWtBarnAddress(),
      name: 'estimateEarnings',
      params: [ids],
    },
  ]
  const [[_, wools, milks]] = await multicall(wtBarnABI, earningsCall)
  return ids.reduce(
    (acc, id, i) => {
      const earnings = acc
      earnings.wool = BigNumber.from(earnings.wool).add(wools[i]).toString() // { wool: wools[i].toString(), milk: milks[i].toString() }
      earnings.milk = BigNumber.from(earnings.milk).add(milks[i]).toString() // { wool: wools[i].toString(), milk: milks[i].toString() }
      return earnings
    },
    { wool: '0', milk: '0' },
  )
}

export const fetchStakedEarnings = async (account: string) => {
  const call = [
    {
      address: getWtBarnAddress(),
      name: 'totalStakesOf',
      params: [account],
    },
  ]
  const [[balanceOf]] = await multicall(wtBarnABI, call)
  const count = balanceOf.toNumber()

  const stakeCalls = Array.from({ length: count }, (_, i) => {
    return {
      address: getWtBarnAddress(),
      name: 'stakeOf',
      params: [account, i],
    }
  })
  const stakes = await multicall(wtBarnStakeOfABI, stakeCalls)
  const ids = stakes.map((s) => s[0].tokenId.toNumber())

  const earningsCall = [
    {
      address: getWtBarnAddress(),
      name: 'estimateEarnings',
      params: [ids],
    },
  ]
  const [[_, wools, milks]] = await multicall(wtBarnABI, earningsCall)


  return ids.reduce(
    (acc, id, i) => {
      const earnings = acc
      earnings[id].wool = BigNumber.from(earnings.wool).add(wools[i]).toString() // { wool: wools[i].toString(), milk: milks[i].toString() }
      earnings[id].milk = BigNumber.from(earnings.milk).add(milks[i]).toString() // { wool: wools[i].toString(), milk: milks[i].toString() }
      return earnings
    },
    { },
  )
}


export const fetchUserLands = async (account) => {
  const call = [
    {
      address: getWtLandAddress(),
      name: 'balanceOf',
      params: [account],
    },
  ]
  const [[balanceOf]] = await multicall(wtLandABI, call)
  const count = balanceOf.toNumber()
  const idCalls = Array.from({ length: count }, (_, i) => {
    return {
      address: getWtLandAddress(),
      name: 'tokenOfOwnerByIndex',
      params: [account, i],
    }
  })
  const ids = await multicall(wtLandABI, idCalls)

  const traitCalls = ids.map((id) => {
    return {
      address: getWtLandAddress(),
      name: 'getTrait',
      params: id
    }
  })

  const traits = await multicall(wtLandABI, traitCalls)


  const lands = ids.map(([id], i) => {
    const power = traits[i].power.toNumber()
    const level = traits[i].level.toNumber()
    const type = traits[i].kind === 0 ? 'farm' : 'mine'
    const imageSmall = `/images/lands/${type}_${level}.png`
    return {
      id: id.toNumber(),
      name:  `Land #${id.toNumber()}`,
      type,
      power,
      level,
      imageSmall
    }
  })

  return { balance: count, lands }
}

export const fetchLands = async (ids: number[]) => {
  const traitCalls = ids.map((id) => {
    return {
      address: getWtLandAddress(),
      name: 'getTrait',
      params: [ id ]
    }
  })

  const traits = await multicall(wtLandABI, traitCalls)


  const lands = ids.map((id, i) => {
    const power = traits[i].power.toNumber()
    const level = traits[i].level.toNumber()
    const type = traits[i].kind === 0 ? 'farm' : 'mine'
    const imageSmall = `/images/lands/${type}_${level}.png`
    return {
      id,
      name:  `Land #${id}`,
      power,
      level,
      type,
      imageSmall
    }
  })

  return lands
}




export default fetchUserAnimals
