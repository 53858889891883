import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'FZXS';
    src: url('/fonts/FZXS24.ttf');
    font-style: normal;
  }
  * {
    font-family: FZXS, HYHeiFang, YaHei, sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
  .marquee {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    box-sizing: border-box;
  }

  .marquee span {
    display: inline-block;
    width: max-content;

    padding-left: 100%;
    will-change: transform;
    animation: marquee 50s linear infinite;
  }

  .marquee span:hover {
    animation-play-state: paused
  }


  @keyframes marquee {
    0% { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
  }

  @media (prefers-reduced-motion: reduce) {
    .marquee span {
      animation-iteration-count: 1;
      animation-duration: 0.01; 
      /* instead of animation: none, so an animationend event is 
       * still available, if previously attached.
       */
      width: auto;
      padding-left: 0;
    }
  }
`

export default GlobalStyle
