import { Flex, IconButton, CogIcon, useModal } from '@pancakeswap/uikit'
import { createGlobalStyle } from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import useTokenBalance, { useGetBnbBalance } from 'hooks/useTokenBalance'
import { getBalanceAmount } from 'utils/formatBalance'
import tokens from 'config/constants/tokens'
import { formatEther } from '@ethersproject/units'
import BigNumber from 'bignumber.js'
import { MoneyFormatText } from 'react-native-moneyformattext'
import numeral from 'numeral'
import SettingsModal from './SettingsModal'
import { parse } from 'querystring'
import { base36_to_num, num_to_base36 } from 'hooks/WolfConfig'
import useToast from 'hooks/useToast'
type Props = {
  color?: string
  mr?: string
}

const MintStyle = createGlobalStyle`
  
  .MenuCss { 
    position:relative;
    top:10px;
    width:100%;
 
    right:-50px;
  } 
  .MenuCss img {  
     position:relative;
     float:left;
    
  } 
  .MenuCss h1 {  
    position:relative;
    float:left; 
    color: #8e4216;
    width:80px; 
    top:21px;
 } 

 .MenuCss a {  
  position:relative;
  float:left; 
  color: #fff;
  width:160px; 
  top:21px;
} 

 

 @media screen and (min-width:968px) {
  .IconButton{
    top: 20px; 
  }
}

 @media screen and (max-width:968px) {
  .MenuCss {
    display: none;
  } 

  .IconButton{
    top: 5px;
  }
}

`

const GlobalSettings = ({ color, mr = '5px' }: Props) => {
  const [onPresentSettingsModal] = useModal(<SettingsModal />)
  const { t } = useTranslation()
  const { balance: wtWOOL } = useTokenBalance(tokens.wtWOOL.address)
  const { balance: wtMilk } = useTokenBalance(tokens.wtMilk.address)
  const { toastSuccess, toastError, inviteId, setInviteId } = useToast()

  const userwtWOOLBalance = getBalanceAmount(wtWOOL)

  const userwtMilkBalance = getBalanceAmount(wtMilk)

  const { balance: bnbBalance } = useGetBnbBalance()

  const formattedBnbBalance = +new BigNumber(formatEther(bnbBalance).toString())
    .decimalPlaces(4, BigNumber.ROUND_DOWN)
    .toNumber()

  const remix = 120

  if (window?.location?.href) {
    const getPageQuery = () => parse(window.location.href.split('?')[1])

    const locationQuery = getPageQuery()
    if (locationQuery && locationQuery.invite) {
      // 读取base64字符串
      let buffer = locationQuery.invite.toString()

      console.log('invite_addressbuffer', buffer)
      // 进行解码
      localStorage.setItem('invite_address', buffer.toString())
      if (buffer) {
        setInviteId(base36_to_num(buffer))
      }
    }
  }

  return (
    <Flex>
      <MintStyle />
      <div className="MenuCss">
        <a style={{ right: '-260px' }} target={'_blank'} href="https://gamefi-zhen-tan-she.gitbook.io/wolftowm/">
          {t('White Paper')}
        </a>
        <img style={{ right: '-230px' }} src="/images/home/wolf/laba.png" alt="" />
        <img
          style={{
            right: '-240px',
            width: '300px',
            height: '55px',
          }}
          src="/images/home/wolf/wtwool.png"
          alt="wool_mint"
        />

        <img
          style={{
            right: '30px',
            top: '15px',
            width: '30px',
            height: '30px',
          }}
          src="/images/home/wolf/money.png"
          alt="wool_mint"
        />

        <h1
          style={{
            right: ' 20px',
          }}
        >
          {numeral(userwtWOOLBalance).format('0,0')}
        </h1>

        <img
          style={{
            right: '10px',
            top: '15px',
            width: '30px',
            height: '30px',
          }}
          src="/images/home/wolf/money.png"
          alt="wool_mint"
        />

        <h1
          style={{
            right: '0px',
          }}
        >
          {' '}
          {numeral(userwtMilkBalance).format('0,0')}{' '}
        </h1>

        <img
          style={{
            right: '-30px',
            top: '0px',
          }}
          src="/images/home/wolf/bnb.png"
          alt="wool_mint"
        />
        <h1
          style={{
            right: '40px',
          }}
        >
          {' '}
          {formattedBnbBalance.toString()}
        </h1>
      </div>

      <IconButton
        className="IconButton"
        onClick={onPresentSettingsModal}
        variant="text"
        scale="sm"
        mr={mr}
        id="open-settings-dialog-button"
      >
        <CogIcon height={24} width={24} color={color || 'textSubtle'} />
      </IconButton>
    </Flex>
  )
}

export default GlobalSettings
