import { BigNumber } from '@ethersproject/bignumber'
import { API_SERVICE_URL } from 'config'
import wtAnimalABI from 'config/abi/wtAnimal.json'
import wtBarnABI from 'config/abi/wtBarnV2.json'
import wtBarnStakeOfABI from 'config/abi/wtBarnStakeOf.json'
import wtBlacklistAbi from 'config/abi/wtBlacklist.json'
import multicall, { multicallv2 } from 'utils/multicall'
import { getWtAnimalAddress, getWtBarnV2Address, getBlacklistAddress } from 'utils/addressHelpers'
import { fetchAnimals } from './fetchData'
import { StakeType } from "../types"


const barnV2Addresss = getWtBarnV2Address()

export const fetchStakedAnimals = async (account) => {

  const call = [
    {
      address: barnV2Addresss,
      name: 'getUserStakedAnimals',
      params: [ StakeType.WOOL, account ],
    },
    {
      address: barnV2Addresss,
      name: 'getUserStakedAnimals',
      params: [ StakeType.MILK, account ],
    },
    {
      address: barnV2Addresss,
      name: 'getUserStakedAnimals',
      params: [ StakeType.WOLF, account ],
    }
  ]

  const [[forWool], [forMilk], [wolves]] = await multicall(wtBarnABI, call)
  const forWoolIds = forWool.map(id => id.toNumber())
  const forMilkIds = forMilk.map(id => id.toNumber())
  const wolfIds = wolves.map(id => id.toNumber())
  const allIds = forWoolIds.concat(forMilkIds).concat(wolfIds)
  const earningsCall = [
    {
      address: barnV2Addresss,
      name: 'estimateEarnings',
      params: [ allIds ],
    }
  ]

  let earnings = null;
  try {
    [{ earnings }] = await multicall(wtBarnABI, earningsCall)
  } catch(e) {
    console.log(e)
  }

  const blockedCalls = wolfIds.map(id => {
    return {
      address: getBlacklistAddress(),
      name: 'blocked',
      params: [ id ],
    }
  })

  let blacklists = null;
  try {
    blacklists = await multicall(wtBlacklistAbi, blockedCalls)
  } catch(e) {
    console.log(e)
  }


  const stakedSheepsForWool = await fetchAnimals(forWoolIds)
  const stakedSheepsForMilk = await fetchAnimals(forMilkIds)
  const stakedWolves = await fetchAnimals(wolfIds)

  if(earnings) {
    stakedSheepsForWool.map((a, i) => {
      a['earnings'] = { wool: earnings[i][1].toString(), milk: '0'  }
    })

    stakedSheepsForMilk.map((a, i) => {
      a['earnings'] = { wool: '0', milk: earnings[i + forWoolIds.length][2].toString()  }
    })

    stakedWolves.map((a, i) => {
      a['earnings'] = { wool: earnings[i + forWoolIds.length + forMilkIds.length ][1].toString(), milk: earnings[i + forWoolIds.length + forMilkIds.length][2].toString()  }
      a['blocked'] = blacklists[i][0]
    })
  }

  return { stakedSheepsForWool, stakedSheepsForMilk, stakedWolves }
}

export default fetchStakedAnimals
