/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BarnV2State } from 'state/types'
import { getAddress } from 'utils/addressHelpers'
import { getErc721Contract } from 'utils/contractHelpers'
// import fetchUserAnimals, { fetchStakedAnimals, fetchTotalEarnings } from './fetchData'
import fetchStakedAnimals from './fetchBarnV2Data'

const initialState: BarnV2State = {
  stakedSheepsForWool: [],
  stakedSheepsForMilk: [],
  stakedWolves: [],
  userDataLoaded: false,
}

// Async thunks

export const fetchStakedAnimalsAsync = createAsyncThunk('wt/fetchStakedAnimalsV2Async', async (account: string) => {
  const data = await fetchStakedAnimals(account)
  return data
})

// export const fetchTotalEarningsAsync = createAsyncThunk('wt/fetchTotalEarningsV2Async', async (account: string) => {
//   const data = await fetchTotalEarnings(account)
//   return data
// })

export const barnSlice = createSlice({
  name: 'barnv2',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStakedAnimalsAsync.fulfilled, (state, action) => {
      state.userDataLoaded = true
      state.stakedSheepsForWool = action.payload.stakedSheepsForWool
      state.stakedSheepsForMilk = action.payload.stakedSheepsForMilk
      state.stakedWolves = action.payload.stakedWolves
    })

    // builder.addCase(fetchTotalEarningsAsync.fulfilled, (state, action) => {
    //   state.totalEarnings = action.payload
    // })
  },
})

export default barnSlice.reducer
